import React from "react";

const Footer = () => {
  return (
    <footer className="bg-slate-900 flex flex-col gap-[1rem] justify-center items-center text-white px-[2rem] py-[1rem]">
      <div className="">
        <h1 className="text-gray-400 font-semibold text-center align-middle mb-[.5rem]">
          &copy; 2024 NextGen Pioneers. All rights reserved.
        </h1>
      </div>
    </footer>
  );
};

export default Footer;
